
.esFooter {
  grid-area: footer;
  align-items: stretch;
  border-color: var(--esColor-theme2);
  border-style: solid;
  border-width: 2px 0 0;
  box-sizing: border-box;
  color: rgba(var(--esColor-base-dark-grey-rgb), 1);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  font-size: var(--es-font-size-14);
  font-family: NexusSansWebPro;
  line-height: var(--es-line-height);
  margin: 32px 0 0;
  padding: 32px 8px;
  width: 100%;
  /* z-index: 2; */
  /* bottom: 0; */
  /* position: absolute; */
  min-height: 15vh;
}

.esFooterContent {
  flex: 1 1 auto;
  margin: 0 8px;
}

.esFooterLinks {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.esFooterLinks > li {
  display: block;
  margin: 0 0 16px 0;
  white-space: nowrap;
}

.esFooterNonsolus {
  align-items: flex-start;
  display: flex;
  margin: 0 8px 24px;
}

.esFooter-nonsolus * {
  display: block;
}

.esFooterRelx {
  align-items: flex-start;
  display: flex;
  margin: 24px 8px 0;
}

.esFooterRelx * {
  display: block;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .esFooter {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
  .esFooterLinks {
    flex-direction: row;
  }
  .esFooterLinks > li {
    margin: 0 24px 8px 0;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .esFooter {
    flex-direction: row;
  }
  .esFooterNonsolus {
    margin: 0 16px 0 8px;
  }
  .esFooterRelx {
    margin: 0 8px 0 16px;
  }
}

@media (min-width: 992px) {
  .esFooter {
    padding-left: 24px;
    padding-right: 24px;
  }
  .esFooterLinks > li {
    margin-right: 32px;
  }
  .esFooterNonsolus {
    margin-right: 24px;
  }
  .esFooterRelx {
    margin: 0 8px 0 24px;
  }
}

.esLink{
  color: var(--esColor-grey8-rgb)
}

.esLink:link{
  text-decoration: none;
}

.esLink:hover{
  text-decoration: underline;
}

.esLink.bold {
  font-weight: bold;
  text-decoration: none;
}

.esLink.underline,
.esLink.inline {
  background-image: linear-gradient(currentColor 0, currentColor 100%);
  background-position: 0 1.1em;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  line-height: calc(1em + 8px);
}

.esLink.external:after {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2024%22%3E%3Cpath%20d%3D%22M13.149%2014.39V5.544H4.224V7.34h5.862L1.98%2015.393l1.347%201.32%208.053-8.106v5.782z%22%20fill%3D%22%23969696%22%2F%3E%3C%2Fsvg%3E);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 0.55em;
  content: "";
  display: inline-block;
  height: 1em;
  vertical-align: middle;
  width: 0.7em;
}
