@charset "UTF-8";

:root{
    --es-line-height: calc(1em + 8px);
}
.inputRow {
    margin: 25px 0;
}

.form-class {
    width: 90%;
    margin: 25px auto;
    border-style: double;
    padding: 10px;
}

.esHtmlBody {
    bottom: 0;
    margin: 0;
    overflow: hidden auto;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    --es-line-height: calc(2em + 4px);
    font-variant-numeric: lining-nums proportional-nums;
}
.enclosedFieldsRow{
    display: table-row;
}
.enclosedFieldsTable{
    display: table;
    width: 90%;
    padding-left: 16px;

}

.separatorContainer {
    margin-left: -12px;
}

@media screen and (min-width: 660px) {
    .separatorContainer {
        margin-left: -28px;
    }
}

.separatorContainer > svg {
    vertical-align: bottom;
}

.separator {
    fill: rgba(var(--esColor-elsevier-blue-rgb));
}

.separatorBlank {
    fill: rgba(var(--esColor-grey0-rgb));
}


@media screen and (min-width: 660px) {
    .enclosedFieldsTable {
        /*border: 2px deeppink solid;*/
        display: table;
        width: 80%;
        padding-left: 64px;
    }
}

.enclosedFieldsGroup {
    /*border: 2px indigo solid;*/
    width: 90%;
    display: table-cell;
    padding-bottom: 44px;
}

.enclosedFieldsAction {
    /*border: 2px greenyellow solid;*/
    width: 10%;
    display: table-cell;
    text-align: center;
    vertical-align: top;
}

.mainScreenContent {
    margin: 0 auto;
    width: auto;
    border: 2px var(--esColor-background) solid;
    border-top: 1px #B9B9B9 solid;
    background-color: var(--esColor-background);
}

.mainScreenContent > span {
    color: #0C7DBB;
}

.errorScreenContent {
    margin: 0 auto;
    width: auto;
    border: 2px var(--esColor-background) solid;
    border-top: 1px #B9B9B9 solid;
    background-color: var(--esColor-background);
    font-family: NexusSansWebPro, serif;
}

.errorScreenContent > span {
    color: #0C7DBB;
}

.logoutContent {
    margin: 0 auto;
    max-width: 1200px;
    max-height: 500px;
    border: 2px var(--esColor-background) solid;
    background-color: var(--esColor-background);
    text-align: center;
}

.mainScreenContentInnerContainer {
    margin: 0 auto;
    padding: 8px;
    border: 2px red solid;
    background-color: var(--esColor-background);
}

@media screen and (min-width: 660px) {
    .mainScreenContentInnerContainer {
        margin: 0 auto;
        padding: 32px;
        border: 2px indigo solid;
        background-color: var(--esColor-background);
    }
}

.logoutContentInnerContainer {
    margin: 0 auto;
    padding: 8% 30%;
    /*border: 2px indigo solid;*/
    text-align: center;
    vertical-align: center;
}

.mainLogoutContent {
    background-color: rgba(var(--esColor-grey0-rgb));
    justify-content: center;
    align-items: center;
}

.exampleContentHeading {
    background-color: var(--esColor-background);
    width: 99.5%;
    height: 80px;
    border: 3px solid var(--esColor-background);
    vertical-align: bottom;
    padding-top: 16px;
}

.nothingToDeclare {
    padding-top: 8px;
    padding-right: 16px;
}

.landingButtonsContainer {
    margin: auto auto 32px;
    max-width: 1152px;
    display: flex;
    flex-direction: row-reverse;
}

@media screen and (min-width: 660px) {
    .landingButtonsContainer {
        width: 100%;
        max-width: 1152px;
        margin: 16px auto;
        height: 64px;

    }
}

.buttonsContainer {
    margin: 0 auto;
    padding: 10px 8px 50px 8px;
}

@media screen and (min-width: 660px) {
    .buttonsContainer {
        margin: 16px auto 0;
        max-width: 1216px;
        padding: 10px 32px 50px 32px;
        display: flow-root;
        /*border: 2px greenyellow solid;*/
    }
}

.saveDiv {
    float: right;
}

.contentLabel {
    padding-top: 16px;
}

.contentLabelChildren {
    font-size: 16px;
}

.contentLabelChildren > ul {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
}

.contentSuperSubText  {
    margin-left: 28px;
    margin-top: -24px;
    font-size: 10px;
    font-style: italic;
    color: #858585;
}

.checkboxMain {
    width: 80%;
    font-size: var(--es-font-size-16);
    margin-left: 36px;
    margin-bottom: 8px;
}

.checkboxMainContainer {
    border-top: 2px solid var(--esColor-grey3);
    padding-top: 8px;
}

.checkboxSpan li {
    list-style: none;
}


.mainQuestionsContent {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 32px;
    padding-right: 32px;
    background-color: rgba(var(--esColor-grey0-rgb));
}

@media screen and (min-width: 660px) {
    .mainQuestionsContent {
        max-width: 1152px;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 32px;
        padding-right: 32px;
        background-color: rgba(var(--esColor-grey0-rgb));
    }
}

.headerSubTextContent {
    font-family: NexusSansWebPro, serif;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #2E2E2E;
    font-size: var(--es-font-size-24);
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 30px;
    padding-right: 30px;
}

.headerSubTextContentElsEmployeeSection {
    font-family: NexusSansWebPro, serif;
    font-style: normal;
    line-height: 32px;
    align-items: center;
    color: #2E2E2E;
    font-size: var(--es-font-size-24);
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 30px;
    padding-right: 30px;
}

.sectionSubText{
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 16px;
}

.thankyouSubTextContent {
    margin: 0 auto;
    width: auto;
    border: 2px white solid;
    border-top: 1px #B9B9B9 solid;
    background-color: var(--esColor-background);
    padding-bottom: 50px;
}

.sectionSubText > a {
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0 0 .1em;
    color: var(--esColor-elsevier-blue);
    font: inherit;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    transition: border-color var(--es-animation-hover), color var(--es-animation-hover);
}

.thankyouSubTextContent > a {
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0 0 .1em;
    color: var(--esColor-elsevier-blue);
    font: inherit;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    transition: border-color var(--es-animation-hover), color var(--es-animation-hover);
}

.esHeadingH2 {
    font-style: normal;
    font-weight: normal;
    line-height: var(--es-line-height);
    margin: 0;
}

.groupChkbxFrame {
    margin: 25px 0;
    border: 1px solid black;
    padding: 5px;
}

.groupEnclosureFrame {
    margin: 25px 0;
    border: 2px double black;
    padding: 5px;
}

.reviewScreenContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.inputText #input {
    display: block;
    size: 50px;
}

.formMessage {
    font-size: 10px;
    padding-top: 2px;
}
.validFormMessage {
    color: #4caf50;
}

.invalidFormMessage {
    font-size: 14px;
    color: #f44336;
}

.hasError {
    border: 1px solid #f44336;
}

.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #eaeaea;

}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 3rem;
    background: #aaaaaa;
    color: white;
    display: flex;
}

.title,
.description {
    text-align: center;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    max-width: 800px;
    margin-top: 3rem;
}

.card {
    margin: 1rem;
    flex-basis: 45%;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
    color: #0070f3;
    border-color: #0070f3;
}

.card h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 10rem;
    line-height: 1.5;
    justify-content: center;
}

@media (max-width: 600px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }
}

/* Version: 2.0.18
 * Source: https://design.elsevier.com/
 */

.esHorizontalRule {
    border: 1px solid var(--esColor-grey6);
    margin: 2em 0;
}

.esProgressIndicator {
    margin: 0 8px 8px 0;
}

.esAnimation {
    --es-animation-appear: 0.3s;
    --es-animation-hover: 0.3s;
    --es-animation-shift: 0.2s;
}

/* The red, green and blue colors are specified as separate values.
 *
 * The advantage of the separate values is that you can use them with
 * an alpha by use the following:
 *
 * color rgba(var(--esColor-warning-rgb), 0.5);
 *
 * Also all the colors are defined already without the rgb and thereby
 * making the following two lines the same.
 *
 * color: rgb(var(--esColor-warning-rgb));
 * color: var(--esColor-warning);
 *
 */
:root,
.esColor,
.esThemeBrightDefault {
    /* Gray colors */
    --esColor-grey0-rgb: 255, 255, 255;
    /* #FFFFFF */
    --esColor-grey1-rgb: 255, 255, 255;
    /* #FFFFFF */
    --esColor-grey2-rgb: 235, 235, 235;
    /* #EBEBEB */
    --esColor-grey3-rgb: 220, 220, 220;
    /* #DCDCDC */
    --esColor-grey4-rgb: 185, 185, 185;
    /* #B9B9B9 */
    --esColor-grey5-rgb: 150, 150, 150;
    /* #969696 */
    --esColor-grey6-rgb: 115, 115, 115;
    /* #737373 */
    --esColor-grey7-rgb: 80, 80, 80;
    /* #505050 */
    --esColor-grey8-rgb: 46, 46, 46;
    /* #53565a */
    --gunmetal-rgb: 83, 86, 90;
    /* #2E2E2E */
    /* Brand colors */
    --esColor-elsevier-orange-rgb: 233, 113, 28;
    /* #E9711C */
    --esColor-elsevier-blue-rgb: 12, 125, 187;
    /* #007398 */
    --esColor-link-blue-rgb: 12, 125, 187;
    /* #0C7DBB */
    --esColor-elsevier-orange-ondark-rgb: 255, 130, 0;
    /* #FF8200 */
    --esColor-elsevier-blue-ondark-rgb: 68, 198, 244;
    /* #44C6F4 */
    /* Messaging colors */
    --esColor-confirmation-rgb: 41, 166, 27;
    /* #29A61B */
    --esColor-information-rgb: 0, 158, 206;
    /* #009ECE */
    --esColor-warning-rgb: 162, 45, 32;
    /* #A22D20 */
    --esColor-confirmation-ondark-rgb: 83, 184, 72;
    /* #53B848 */
    --esColor-information-ondark-rgb: 68, 198, 244;
    /* #44C6F4 */
    --esColor-warning-ondark-rgb: 255,106,90;
    /* #FF6A5A */
    /* Color swatches */
    --esColor-base-dark-grey-rgb: 83, 86, 90;
    /* #53565A */
    --esColor-base-white-rgb: 255, 255, 255;
    /* #FFFFFF */
    --esColor-primary-light-blue-rgb: 12, 125, 187;
    /* #0C7DBB */
    --esColor-primary-orange-rgb: 255, 108, 0;
    /* #FF6C00 */
    --esColor-primary-elsevier-blue-rgb: 0, 115, 152;
    /* #007398 */
    --esColor-secondary-cool-grey-1-rgb: 220, 220, 221;
    /* #DCDCDD */
    --esColor-secondary-very-pale-blue-rgb: 205, 228, 255;
    /* #CDE4FF */
    --esColor-secondary-very-pale-orange-rgb: 255, 240, 228;
    /* #FFF0E4 */
    --esColor-tertiary-blue1-rgb: 172, 210, 255;
    /* #ACD2FF */
    --esColor-tertiary-blue2-rgb: 12, 125, 187;
    /* #0C7DBB */
    --esColor-tertiary-blue3-rgb: 7, 57, 115;
    /* #073973 */
    --esColor-tertiary-green1-rgb: 192, 242, 93;
    /* #C0F25D */
    --esColor-tertiary-green2-rgb: 142, 215, 0;
    /* #8ED700 */
    --esColor-tertiary-green3-rgb: 73, 110, 1;
    /* #496E01 */
    --esColor-tertiary-purple1-rgb: 187, 132, 255;
    /* #BB84FF */
    --esColor-tertiary-purple2-rgb: 102, 28, 202;
    /* #661CCA */
    --esColor-tertiary-purple3-rgb: 60, 18, 118;
    /* #3C1276 */
    --esColor-tertiary-red1-rgb: 254, 183, 183;
    /* #FEB7B7 */
    --esColor-tertiary-red2-rgb: 247, 62, 41;
    /* #F73E29 */
    --esColor-tertiary-red3-rgb: 169, 43, 29;
    /* #A92B1D */
    --esColor-tertiary-yellow1-rgb: 255, 236, 132;
    /* #FFEC84 */
    --esColor-tertiary-yellow2-rgb: 253, 211, 0;
    /* #FDD300 */
    --esColor-tertiary-yellow3-rgb: 151, 101, 0;
    /* #976500 */
}

:root,
.esColor,
.esThemeBrightDefault {
    /* Link theme color to the specific color values */
    --esColor-background-rgb: var(--esColor-grey0-rgb);
    --esColor-border-rgb: var(--esColor-grey6-rgb);
    --esColor-disabled-rgb: var(--esColor-grey4-rgb);
    --esColor-error-rgb: var(--esColor-warning-rgb);
    --esColor-focus-rgb: 255, 108, 0;
    --esColor-foreground-rgb: var(--esColor-grey8-rgb);
    --esColor-link-rgb: var(--esColor-link-blue-rgb);
    --esColor-theme1-rgb: var(--esColor-elsevier-blue-rgb);
    --esColor-theme2-rgb: var(--esColor-primary-orange-rgb);
}

:root,
.esColor,
.esThemeBrightDefault {
    /* Gray colors */
    --esColor-grey0: rgba(var(--esColor-grey0-rgb), 1);
    --esColor-grey1: rgba(var(--esColor-grey1-rgb), 1);
    --esColor-grey2: rgba(var(--esColor-grey2-rgb), 1);
    --esColor-grey3: rgba(var(--esColor-grey3-rgb), 1);
    --esColor-grey4: rgba(var(--esColor-grey4-rgb), 1);
    --esColor-grey5: rgba(var(--esColor-grey5-rgb), 1);
    --esColor-grey6: rgba(var(--esColor-grey6-rgb), 1);
    --esColor-grey7: rgba(var(--esColor-grey7-rgb), 1);
    --esColor-grey8: rgba(var(--esColor-grey8-rgb), 1);
    --gunmetal: rgba(var(--gunmetal-rgb), 1);
    /* Brand colors */
    --esColor-link-blue: rgba(var(--esColor-link-blue-rgb), 1);
    --esColor-elsevier-orange: rgba(var(--esColor-elsevier-orange-rgb), 1);
    --esColor-elsevier-blue: rgba(var(--esColor-elsevier-blue-rgb), 1);
    --esColor-elsevier-orange-ondark: rgba(var(--esColor-elsevier-orange-ondark-rgb), 1);
    --esColor-elsevier-blue-ondark: rgba(var(--esColor-elsevier-blue-ondark-rgb), 1);
    /* Messaging colors */
    --esColor-confirmation: rgba(var(--esColor-confirmation-rgb), 1);
    --esColor-information: rgba(var(--esColor-information-rgb), 1);
    --esColor-warning: rgba(var(--esColor-warning-rgb), 1);
    --esColor-confirmation-ondark: rgba(var(--esColor-confirmation-ondark-rgb), 1);
    --esColor-information-ondark: rgba(var(--esColor-information-ondark-rgb), 1);
    --esColor-warning-ondark: rgba(var(--esColor-warning-ondark-rgb), 1);
    /* Theme colors */
    --esColor-background: rgba(var(--esColor-background-rgb), 1);
    --esColor-border: rgba(var(--esColor-border-rgb), 1);
    --esColor-disabled: rgba(var(--esColor-disabled-rgb), 1);
    --esColor-error: rgba(var(--esColor-error-rgb), 1);
    --esColor-focus: rgba(var(--esColor-focus-rgb), 1);
    --esColor-foreground: rgba(var(--esColor-foreground-rgb), 1);
    --esColor-link: rgba(var(--esColor-link-rgb), 1);
    --esColor-theme1: rgba(var(--esColor-theme1-rgb), 1);
    --esColor-theme2: rgba(var(--esColor-theme2-rgb), 1);
}

:root,
.esColor,
.esThemeBrightDefault {
    background-color: var(--esColor-background);
    color: var(--esColor-foreground);
}

/* typography Nexus sans-serif */
@font-face {
    font-display: swap;
    font-family: 'NexusSansWebPro';
    src: url("../resources/fonts/NexusSansWebPro-Regular.woff2") format("woff2"), url("../resources/fonts/NexusSansWebPro-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: 'NexusSansWebPro';
    font-weight: bold;
    src: url("../resources/fonts/NexusSansWebPro-Bold.woff2") format("woff2"), url("../resources/fonts/NexusSansWebPro-Bold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: 'NexusSansWebPro';
    font-style: italic;
    src: url("../resources/fonts/NexusSansWebPro-Italic.woff2") format("woff2"), url("../resources/fonts/NexusSansWebPro-Italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: 'NexusSansWebPro';
    font-style: italic;
    font-weight: bold;
    src: url("../resources/fonts/NexusSansWebPro-BoldItalic.woff2") format("woff2"), url("../resources/fonts/NexusSansWebPro-BoldItalic.woff") format("woff");
}

:root,
.esTypography {
    /* All allowed font-sizes */
    --es-font-size-12: 12px;
    --es-font-size-14: 14px;
    --es-font-size-16: 16px;
    --es-font-size-20: 16px;
    --es-font-size-24: 20px;
    --es-font-size-32: 24px;
    --es-font-size-40: 32px;
    --es-font-size-48: 32px;
    --es-font-size-56: 32px;
    --es-font-size-64: 32px;
    --es-font-sans-serif: Arial, Helvetica,   /**/ Lucida Sans Unicode, Microsoft Sans Serif,   /**/ Segoe UI Symbol, STIXGeneral, Cambria Math,   /**/ Arial Unicode MS, sans-serif;
    --es-font-serif: Georgia, Times New Roman,   /**/ STIXGeneral, Cambria Math, Arial, Helvetica,   /**/ Lucida Sans Unicode, Microsoft Sans Serif,   /**/ Segoe UI Symbol, Arial Unicode MS, serif;
}

@media (min-width: 576px) {
    :root,
    .esTypography {
        --es-font-size-24: 24px;
        --es-font-size-32: 32px;
        --es-font-size-40: 40px;
        --es-font-size-48: 40px;
        --es-font-size-56: 40px;
        --es-font-size-64: 40px;
    }
}

@media (min-width: 768px) {
    :root,
    .esTypography {
        --es-font-size-20: 20px;
        --es-font-size-48: 48px;
        --es-font-size-56: 48px;
        --es-font-size-64: 48px;
    }
}

@media (min-width: 992px) {
    :root,
    .esTypography {
        --es-font-size-56: 56px;
        --es-font-size-64: 56px;
    }
}

@media (min-width: 1200px) {
    :root,
    .esTypography {
        --es-font-size-64: 64px;
    }
}

.esHtmlBody {
    bottom: 0;
    margin: 0;
    padding: 0;
    font-size: var(--es-font-size-20);
    font-style: normal;
    font-weight: normal;
    line-height: var(--es-line-height);
    overflow: visible;
}

.esHtmlBody,
.esFontNexusSansWebPro {
    --es-line-height: calc(1em + 8px);
    font-family: NexusSansWebPro, var(--es-font-sans-serif);
}

.esFontNexusSerif {
    --es-line-height: calc(1em + 8px);
    font-family: NexusSerif, var(--es-font-serif);
}

.esFontGulliver {
    --es-line-height: calc(1em + 16px);
    font-family: Gulliver, var(--es-font-serif);
}

.esFontElsevierDisplay {
    --es-line-height: calc(1em + 8px);
    font-family: ElsevierDisplay, NexusSansWebPro, var(--es-font-sans-serif);
}

.esFontElsevierDisplayBoldCondensed {
    --es-line-height: calc(1em + 8px);
    font-family: ElsevierDisplayCondensed, NexusSansWebPro, var(--es-font-sans-serif);
    font-weight: 700;
}

.esFontElsevierDisplayRegularCondensed {
    --es-line-height: calc(1em + 8px);
    font-family: ElsevierDisplayCondensed, NexusSansWebPro, var(--es-font-sans-serif);
    font-weight: 400;
}

.esFontSansSerif {
    --es-line-height: calc(1em + 8px);
    font-family: var(--es-font-sans-serif);
}

.esFontSerif {
    --es-line-height: calc(1em + 8px);
    font-family: var(--es-font-serif);
}

.esFontNumeralParagraph {
    font-variant-numeric: oldstyle-nums proportional-nums;
}

.esHtmlBody,
.esFontNumeralDefault {
    font-variant-numeric: lining-nums proportional-nums;
}

.esFontNumeralTable {
    font-variant-numeric: lining-nums tabular-nums;
}

.esFontSmallCaps {
    /* Need the Nexus webfont with SmallCapitals to work */
    font-variant-caps: small-caps;
}

.esHeadingH1,
.esHeadingH2,
.esHeadingH3,
.esHeadingH4,
.esHeadingH5,
.esHeadingH6,
.esHeadingH5Caps,
.esHeadingH6Caps,
.esIntro,
.esBody,
.esMeta,
.esBatch {
    font-style: normal;
    font-weight: normal;
    line-height: 42px;
    margin: 0;
}

.esHeadingH2,
.esHeadingH3,
.esHeadingH4,
.esHeadingH5,
.esHeadingH6,
.esHeadingH5Caps,
.esHeadingH6Caps {
    margin: 1em 0 calc(1em - 8px);
}

.esHeadingH1 + .esHeadingH2,
.esHeadingH2 + .esHeadingH3,
.esHeadingH3 + .esHeadingH4,
.esHeadingH4 + .esHeadingH5,
.esHeadingH5 + .esHeadingH6 {
    margin-top: calc(1em - 8px);
}

.esHeadingH1 {
    font-size: var(--es-font-size-48);
}

.esHeadingH2 {
    font-size: var(--es-font-size-40);
}

.esHeadingH3 {
    font-size: var(--es-font-size-32);
}

.esHeadingH4,
.esHeadingH5,
.esHeadingH5Caps {
    font-size: var(--es-font-size-24);
}

.esHeadingH6,
.esIntro {
    font-size: var(--es-font-size-20);
}

.esHeadingH6Caps,
.esBody {
    font-size: var(--es-font-size-16);
}

.esMeta {
    font-size: var(--es-font-size-14);
}

.esBatch {
    font-size: var(--es-font-size-12);
}

.esHeadingH5Caps,
.esHeadingH6Caps {
    background-image: linear-gradient(var(--esColor-theme2), var(--esColor-theme2));
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 32px 4px;
    font-weight: 700;
    padding-bottom: 0.5em;
    text-transform: uppercase;
}

.esAccordion {
    border-bottom: 2px solid var(--esColor-grey3);
    margin-bottom: 16px;
}

.esAccordionChecklist {
    list-style: none;
    columns: 300px 2;
}

.esChecklist {
    padding-inline-start: 0;
    display: contents;
}

.esChecklist > li{
    display: grid;
    padding-top: 4px;
}

.esAccordionHeading {
    border-color: var(--esColor-grey3);
    border-style: solid;
    border-width: 2px 0 0;
    font-size: 20px;
    font-weight: normal;
    line-height: calc(1em + 8px);
    margin: 0;
}

.esAccordionHeading > button {
    background-color: transparent;
    border: 0;
    display: block;
    font: inherit;
    height: 3em;
    padding: 0;
    position: relative;
    margin: 0;
    width: 100%;
    text-align: left;
}

.esAccordionHeading > button:focus {
    outline: none;
}

.esAccordionHeading > button[aria-expanded="true"] {
    margin-bottom: 1em;
}

.esAccordionHeading > button > span {
    border-bottom: 2px solid transparent;
    margin: 0 0 0 2em;
}

.esAccordionHeading > button > span:first-child {
    margin: 0 2em 0 0;
}

.esAccordionHeading > button:hover > span,
.esAccordionHeading > button[aria-expanded="true"] > span {
    border-bottom: 2px solid var(--esColor-theme2);
}

.esAccordionHeading > button > svg {
    box-sizing: border-box;
    color: var(--esColor-theme1);
    fill: currentColor;
    height: 2em;
    left: 0;
    padding: calc(0.5em - 2px) 0;
    position: absolute;
    top: 8px;
    width: auto;
}

.esAccordionHeading > button > svg:last-child {
    left: auto;
    right: 0;
}

.esAccordionHeading > button:hover > svg {
    color: var(--esColor-theme2);
}

.esAccordionHeading > button[aria-expanded="true"] > svg {
    transform: scaleY(-1);
}

.esAccordion:last-of-type + * {
    border-color: var(--esColor-grey3);
    border-style: solid;
    border-width: 0 0 2px;
}

.esAccordionContent.isHidden {
    display: none;
}

/* Alternative button style */
.esButtonAlternative {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    color: inherit;
    font: inherit;
    height: 2em;
    line-height: 1;
    margin: 0;
    padding: 0;
}

/* Button sizes, by default size is inherited from the parent */
.esButtonAlternative.small {
    font-size: var(--es-font-size-16);
}

.esButtonAlternative.medium {
    font-size: var(--es-font-size-20);
}

.esButtonAlternative.large {
    font-size: var(--es-font-size-24);
}

.esButtonAlternative > span {
    box-shadow: 0 -2px 0 0 transparent inset;
    display: inline-block;
    margin: 0 .5em;
    padding: calc(.45em - 2px) 0 calc(.55em - 2px);
    transition: box-shadow var(--es-animation-hover);
    vertical-align: middle;
}

.esButtonAlternative > svg {
    border: 2px solid transparent;
    box-sizing: border-box;
    fill: currentColor;
    height: 2em;
    padding: calc(.5em - 4px);
    vertical-align: middle;
    width: 2em;
    transition: background-color var(--es-animation-hover), border-color var(--es-animation-hover), fill var(--es-animation-hover);
}

/* Primary */
.esButtonAlternative.primary > svg {
    background-color: var(--esColor-theme1);
    border-color: var(--esColor-theme1);
    color: var(--esColor-background);
}

/* Secondary */
.esButtonAlternative.secondary > svg {
    border-color: var(--esColor-theme1);
}

/* Tertiary */
.esButtonAlternative.tertiary > svg {
    border-color: var(--esColor-grey6);
}

/* Focus state for primary, secondary and tertiary */
.esButtonAlternative:hover > span {
    box-shadow: 0 -2px 0 0 var(--esColor-theme2) inset;
}

.esButtonAlternative:active > svg,
.esButtonAlternative:hover > svg {
    background-color: var(--esColor-background);
    border-color: var(--esColor-theme2);
    color: var(--esColor-foreground);
    fill: currentColor;
}

/* Button on dark background */
.esButtonAlternative.background {
    background: none;
    color: currentColor;
}

.esButtonAlternative.background > svg {
    border-color: currentColor;
}

.esButtonAlternative.background:hover > span {
    box-shadow: 0 -2px 0 0 currentColor inset;
}

.esButtonAlternative.background:hover > svg {
    border-color: var(--esColor-background);
    background-color: var(--esColor-background);
}

/* Disabled button */
.esButtonAlternative[aria-disabled="true"] > span,
.esButtonAlternative[aria-disabled="true"] > span:hover,
.esButtonAlternative[disabled] > span,
.esButtonAlternative[disabled] > span:hover {
    background-color: transparent;
    background-image: none;
    box-shadow: 0 -2px 0 0 transparent inset;
    color: var(--esColor-disabled);
}

.esButtonAlternative[aria-disabled="true"] > svg,
.esButtonAlternative[aria-disabled="true"] > svg:hover,
.esButtonAlternative[disabled] > svg,
.esButtonAlternative[disabled] > svg:hover {
    background-color: transparent !important;
    border-color: var(--esColor-disabled) !important;
    fill: var(--esColor-disabled) !important;
}

.esButtonAlternative.primary[aria-disabled="true"] > svg,
.esButtonAlternative.primary[aria-disabled="true"] > svg:hover,
.esButtonAlternative.primary[disabled] > svg,
.esButtonAlternative.primary[disabled] > svg:hover {
    background-color: var(--esColor-disabled) !important;
    fill: var(--esColor-background) !important;
}

.esButtonAlternative.background[aria-disabled="true"] > span,
.esButtonAlternative.background[disabled] > span {
    box-shadow: 0 0 0 0 transparent inset !important;
}

.esBreadcrumb > ol {
    color: var(--esColor-grey6);
    display: flex;
    flex-wrap: wrap;
    font-size: var(--es-font-size-16);
    line-height: var(--es-line-height);
    list-style: none;
    margin: 0;
    padding: 0;
}

.esBreadcrumb > ol > li > a:focus,
.esBreadcrumb > ol > li > a:hover,
.esBreadcrumb > ol > li > a[aria-current=page] {
    color: var(--esColor-foreground);
}

.esBreadcrumb > ol > li {
    padding: 0 8px 0 0;
}

.esBreadcrumb > ol > li:nth-child(n+2) {
    padding: 0 8px 0 16px;
    text-indent: -16px;
}

.esBreadcrumb > ol > li:nth-child(n+2):before {
    color: var(--esColor-foreground);
    content: '>';
    display: inline-block;
    font-size: 75%;
    text-indent: 0;
    vertical-align: 1px;
    width: 14px;
}

/* Default button style */
.esButton {
    -webkit-appearance: none;
    background-color: var(--esColor-background);
    border: 2px solid transparent;
    box-sizing: border-box;
    color: inherit;
    font: inherit;
    height: 2em;
    line-height: 1;
    margin: 0;
    padding: 0 calc(0.8em - 2px);
    /* 16 - 2px border */
    transition: background-color var(--es-animation-hover), border-color var(--es-animation-hover), color var(--es-animation-hover);
}

a.esButton {
    display: inline-block;
}

/* Button sizes, by default size is inherited from the parent */
.esButton.small {
    font-size: var(--es-font-size-16);
}

.esButton.medium {
    font-size: var(--es-font-size-20);
}

.esButton.large {
    font-size: var(--es-font-size-24);
}

/* Button with icon */
.esButton > span {
    display: inline-block;
    padding: calc(.45em - 2px) 0 calc(.55em - 2px);
    vertical-align: middle;
}

.esButton > svg {
    box-sizing: border-box;
    fill: currentColor;
    height: calc(2em - 4px);
    padding: calc(.5em - 4px);
    vertical-align: middle;
    width: calc(2em - 4px);
    transition: fill var(--es-animation-hover);
}

.esButton > svg:first-child {
    margin: 0 0 0 calc(-0.8em + 2px);
}

.esButton > svg:last-child {
    margin: 0 calc(-0.8em + 2px) 0 0;
}

.esButton > svg:first-child:last-child {
    margin: 0 calc(-0.8em + 2px);
}

/* Primary */
.esButton.primary {
    background-color: var(--esColor-theme1);
    border-color: var(--esColor-theme1);
    color: var(--esColor-background);
}

/* Secondary */
.esButton.secondary {
    border-color: var(--esColor-theme1);
}

/* Tertiary */
.esButton.tertiary {
    border-color: var(--esColor-grey6);
}

/* Quatarnary */
.esButton.quatarnary {
    border-color: transparent;
}

/* Focus state for primary, secondary, tertiary and quatarnary */
.esButton:active,
.esButton:hover {
    background-color: var(--esColor-background);
    border-color: var(--esColor-theme2);
    color: var(--esColor-foreground);
}

.esButton[aria-pressed="true"] {
    background-color: var(--esColor-theme1);
    border-color: var(--esColor-theme1);
    color: var(--esColor-background);
}

.esButton[aria-pressed="true"] > svg {
    fill: currentColor;
}

/* Button on dark background */
.esButton.background {
    background: none;
    border-color: currentColor;
    color: currentColor;
}

.esButton.background[aria-pressed="true"] > svg,
.esButton.background:hover {
    background-color: var(--esColor-background);
    border-color: var(--esColor-background);
    color: var(--esColor-grey8);
}

/* Disabled button */
.esButton[disabled],
.esButton[disabled]:hover {
    background-color: transparent;
    border-color: var(--esColor-disabled);
    color: var(--esColor-disabled);
}

.esButton[disabled] > svg {
    fill: var(--esColor-disabled);
}

.esButton.primary[disabled],
.esButton.primary[disabled]:hover {
    background-color: var(--esColor-disabled);
    color: var(--esColor-background);
}

.esButton.primary[disabled] > svg {
    fill: var(--esColor-background);
}

/* Checkbox sizes, by default size is inherited from the parent */
.esCheck.small,
.esCheckInput.small {
    font-size: var(--es-font-size-16);
    line-height: var(--es-line-height);
}

.esCheck.medium,
.esCheckInput.medium {
    font-size: var(--es-font-size-20);
    line-height: var(--es-line-height);
}

.esCheck.large,
.esCheckInput.large {
    font-size: var(--es-font-size-24);
    line-height: var(--es-line-height);
}

.esCheckInput {
    -webkit-appearance: none;
    background-color: var(--esColor-background);
    border: 2px solid var(--esColor-border);
    box-shadow: 0 0 0 0.15em var(--esColor-background) inset;
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    height: calc(1em + 4px);
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: calc(1em + 4px);
    transition: background-color var(--es-animation-hover), border-color var(--es-animation-hover);
}

.esCheckInput[type=radio] {
    border-radius: 50%;
}

.esRadioButtonUl {
    list-style: none;
    padding-left: 0;
    display: contents;
    padding-bottom: 8px;
}

.radioButtonGroupLabel {
    padding-top: 16px;
}

.esCheckInput:hover {
    border-color: var(--esColor-foreground);
}

.esCheckInput:checked,
.esCheckInput[type=checkbox]:indeterminate {
    background-color: var(--esColor-theme2);
}

.esCheckInput[type=checkbox]:indeterminate:before {
    border-color: white transparent transparent white;
    border-style: solid;
    border-width: 0.5em;
    content: '';
    display: block;
}

label > .esCheckInput {
    margin-right: 0.5em;
}

.esCheckInput + label {
    margin-left: 0.5em;
    vertical-align: middle;
}

.esCheck > span {
    vertical-align: middle;
}

/* Disabled checkbox */
.esCheckInput[disabled] {
    border: 2px solid var(--esColor-disabled) !important;
    background-color: var(--esColor-background) !important;
}

.esCheckInput[disabled]:checked,
.esCheckInput[disabled][type=checkbox]:indeterminate {
    background-color: var(--esColor-disabled) !important;
}

.partitioned {
    padding-left: 15px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 220px;
}

/* TODO: merge some styling with the popup. They are very similar. */
/* added to the body when dialog is open, stop main document from
 * scrolling in the background. */
body.esDialogOpen {
    overflow: hidden;
}

.esDialog {
    align-items: center;
    background-color: rgba(var(--esColor-grey6-rgb), 0.85);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    overflow: hidden auto;
    /*padding: 32px 0;              Not Relevant as a popout window*/
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}

.esDialog[hidden] {
    display: none;
}

.esDialogWindow {
    background-color: #fff;
    /*box-shadow: 16px 0 0 0 var(--esColor-theme2) inset;*/
    box-sizing: border-box;
    /*max-width: calc(100vw);       Not Relevant as a popout window*/
    position: relative;
    height: 100%;                   /* Fill the space since its a popout window */
}

.esDialogFocusTrap {
    position: absolute;
}

.esThankyouHeader {
    color: var(--esColor-grey8);
    margin: 0 0 24px;
    padding-top: 32px;
}

.esDialogHeader {
    color: var(--esColor-elsevier-orange);
    padding-left: 25px;
    display: inline;
}

.esDialogHeader:before {
    /* To keep the header text away from the close icon */
    content: '';
    float: right;
    height: 1em;
    width: 32px;
}

.esDialogButtons {
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin-right: 70px;
}

.esDialogButtons .esButton {
    margin: 8px;
}

.esDialogButtons .fullWidth .esButton {
    width: 100%;
}

.esDialogClose {
    background-color: transparent;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    padding: 8px 8px 6px;
    position: absolute;
    right: 4px;
    top: 4px;
}

.esDialogClose > svg {
    border-bottom: 2px solid transparent;
    box-sizing: content-box;
    display: block;
    fill: currentColor;
    height: calc(1em + 4px);
    width: calc(1em + 4px);
}

.esDialogClose:hover > svg {
    border-bottom: 2px solid var(--esColor-theme2);
}

/* Larger padding, bigger content */
.esDialogContent.large {
    margin: 50px 32px 48px;
}

.esDialogContent.large .esDialogHeader {
    text-align: center;
}

.esDialogContent.large .esDialogHeader:before {
    content: none;
}

.esDialogContent.large .esDialogButtons {
    margin-top: 32px;
}

.esDialogContent.large + .esDialogClose {
    top: 12px;
}

.esFieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

.esFieldset > legend {
    border: 0;
    margin: 0 0 8px;
    padding: 0;
}

.esFieldset > ol {
    border: 0;
    font-size: var(--es-font-size-16);
    line-height: var(--es-line-height);
    list-style: none;
    margin: 0;
    padding: 0;
}

.esFieldset > ol > li {
    margin: 4px 0 0;
}

:focus {
    outline-offset: 1px;
    outline: var(--esColor-background) solid 3px;
}

/* Disable Firefox specific focus ring, we got our own. */
::-moz-focus-inner {
    border: 0;
    outline: 0;
}

/* Don't show focus ring on elements that are */
/* removed from the default navigation flow */
[tabindex="-1"]:focus {
    outline-style: inherit;
}

.esIconLink {
    align-items: center;
    background-color: transparent;
    border-style: none;
    color: inherit;
    display: inline-flex;
    font: inherit;
    padding: 0;
    text-decoration: none;
    transition: color var(--es-animation-hover);
}

.esIconLink.colored,
.esIconLink.theme {
    color: var(--esColor-link);
}

.esIconLink:hover {
    color: inherit;
}

/* Icon */
.esIconLink > svg {
    color: inherit;
    fill: currentColor;
}

.esIconLink > .icon {
    color: var(--esColor-elsevier-blue);
    height: calc(1em + 4px);
    width: auto;
    transition: color var(--es-animation-hover), transform var(--es-animation-hover);
}

.esIconLink.aligned > .icon {
    width: calc(1em + 4px);
}

.esIconLink[aria-expanded="true"] > .icon {
    color: var(--esColor-theme2);
    transform: scaleY(-1);
}

.esIconLink > .icon:first-child {
    margin-right: 0.4em;
}

.esIconLink > .icon:last-child {
    margin-left: 0.4em;
}

.esIconLink:hover .icon {
    color: var(--esColor-theme2);
}

/* Disabled, aria-disabled for links and disabled for buttons */
.esIconLink[aria-disabled="true"] > .icon,
.esIconLink[aria-disabled="true"] span,
.esIconLink[aria-disabled="true"],
.esIconLink[aria-disabled="true"]:hover > .icon,
.esIconLink[aria-disabled="true"]:hover span,
.esIconLink[disabled] > .icon,
.esIconLink[disabled] span,
.esIconLink[disabled],
.esIconLink[disabled]:hover > .icon,
.esIconLink[disabled]:hover span {
    border-bottom-color: transparent;
    color: var(--esColor-grey5);
    cursor: default;
}

.esInputText {
    display: block;
    position: relative;
    font-size: var(--es-font-size-20);
    line-height: var(--es-line-height);
}

.esInputText.small {
    font-size: var(--es-font-size-16);
}

.esInputText.large {
    font-size: var(--es-font-size-24);
}

.esInputText > input {
    -webkit-appearance: none;
    background-color: transparent;
    border-color: var(--esColor-border);
    border-style: solid;
    border-width: 0 0 2px;
    box-sizing: border-box;
    color: inherit;
    /*display: block;*/
    font: inherit;
    height: 3.2em;
    margin: 0;
    padding: 1em 0 0;
    position: relative;
    vertical-align: text-bottom;
    width: 70%;
}

/* Darken border line on focus/hover */
.esInputText > input:focus,
.esInputText > input:hover {
    border-color: var(--esColor-foreground);
}

/* Label placement */
.esInputText > label {
    color: var(--esColor-theme1);
    display: block;
    font-size: var(--es-font-size-16);
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    z-index: 1;
    transition: font-size var(--es-animation-hover), transform var(--es-animation-hover);
}

.esInputText.small > label {
    font-size: var(--es-font-size-14);
}

/*Styles for TextArea*/
.esInputTextArea {
    padding: 16px;
    border: 2px solid #808080;
    width: 100%;
    height: 150px;
    font-size: var(--es-font-size-16);
    font-family: 'NexusSansWebPro';
}

.esInputTextArea:focus {
    border-color: var(--esColor-foreground);
}

.esInputTextArea::placeholder {
    font-style: italic;

}

.esInputTextAreaDiv {
    padding-left: 16px;
    width: 80%;
}

@media screen and (min-width: 660px) {
    .esInputTextAreaDiv {
        padding-left: 64px;
        width: 80%;
    }
}

/* Move label to the text field when empty (placeholder visible) */
.esInputText > input:placeholder-shown + label {
    cursor: text;
    font-size: inherit;
    transform: translateY(1.6em);
}

/* But not when input field has focus */
.esInputText > input:focus + label {
    cursor: default;
    font-size: var(--es-font-size-16);
    transform: translateY(0);
}

.esInputText.small > input:focus + label {
    font-size: var(--es-font-size-14);
}

/* Error text */
.esInputText.error > input {
    border-color: var(--esColor-error);
}

.esInputText > .esInputText-error {
    color: var(--esColor-error);
    font-size: var(--es-font-size-16);
    line-height: var(--es-line-height);
}

.esInputText > .esInputText-error:empty {
    display: none;
}

.esInputText.small > .esInputText-error {
    font-size: var(--es-font-size-14);
}

.esLink.bold {
    font-weight: bold;
}

.sectionSubText > a,
.esLink.colored,
.esLink.theme {
    color: var(--esColor-link);
}

.esLink.underline,
.esLink.inline {
    background-image: linear-gradient(currentColor 0, currentColor 100%);
    background-position: 0 1.1em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    line-height: calc(1em + 8px);
}

.esLink.external:after {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2024%22%3E%3Cpath%20d%3D%22M13.149%2014.39V5.544H4.224V7.34h5.862L1.98%2015.393l1.347%201.32%208.053-8.106v5.782z%22%20fill%3D%22%23969696%22%2F%3E%3C%2Fsvg%3E);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 0.55em;
    content: '';
    display: inline-block;
    height: 1em;
    vertical-align: middle;
    width: 0.7em;
}

.esList {
    counter-reset: es-list 0;
    list-style: none;
    padding-left: 32px;
}

.esList > li {
    position: relative;
}

.esList > li:nth-last-child(n+2) {
    margin: 0 0 8px;
}

.esList > li:before {
    color: var(--esColor-theme2);
    counter-increment: es-list;
    position: absolute;
    right: 100%;
}

ol.esList > li:before {
    content: counter(es-list) ".";
    font-weight: 700;
    padding-right: .25em;
}

ul.esList > li:before {
    content: '•';
    padding-right: .5em;
}

.esList.none {
    padding-left: 0;
}

.esList.none > :before {
    content: none;
}

.esPagination > ul {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
}

.esPagination li {
    display: inline-block;
    padding: 0;
    margin: 0 16px 0 0;
}

.esPagination li:last-child {
    margin-right: 0;
}

.esPagination li > .esIconLink {
    display: flex;
}

.esPagination [aria-current="page"] {
    border-bottom: 2px solid currentColor;
    font-weight: 700;
}

.esPara {
    font-variant-ligatures: common-ligatures;
    font-variant-numeric: oldstyle-nums proportional-nums;
    margin: 0 0 16px;
    max-width: 34em;
}

.esPara sub,
.esPara sup {
    font-size: 70%;
    line-height: 1;
    font-variant-numeric: lining-nums proportional-nums;
}

.esPara.fullWidth {
    max-width: none;
}

/* TODO: merge some styling with the dialog. They are very similar. */
.esPopup {
    background-color: rgba(var(--esColor-grey2-rgb), 0.85);
    border-color: var(--esColor-theme2);
    border-style: solid;
    border-width: 2px 0 0;
    box-sizing: border-box;
    left: 0;
    max-width: calc(100vw);
    padding: 16px;
    position: fixed;
    top: 0;
    z-index: 99;
}

.esPopup[hidden] {
    display: none;
}

.esPopup.small {
    width: 352px;
}

.esPopup.medium {
    width: 512px;
}

.esPopup.large {
    width: 672px;
}

.esPopupContent {
    background-color: var(--esColor-background);
    position: relative;
    padding: 32px 16px;
}

.esPopupFocusTrap {
    position: absolute;
}

.esPopupPointer {
    display: block;
    height: 18px;
    position: absolute;
    width: 18px;
}

.esPopupHeader {
    margin: 0 0 24px;
    padding: 0;
}

.esPopupHeader:before {
    /* To keep the header text away from the close icon */
    content: '';
    float: right;
    height: 1em;
    width: 32px;
}

.esPopupButtons {
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin: 16px -8px -8px;
}

.esPopupButtons .esButton {
    margin: 8px;
}

.esPopupButtons.fullWidth .esButton {
    width: 100%;
}

.esPopupClose {
    background-color: transparent;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    padding: 8px 8px 6px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.esPopupClose > svg {
    border-bottom: 2px solid transparent;
    box-sizing: content-box;
    display: block;
    fill: currentColor;
    height: calc(1em + 4px);
    width: calc(1em + 4px);
}

.esPopupClose:hover > svg {
    border-bottom: 2px solid var(--esColor-theme2);
}

/* Popup below the activator */
.below > .esPopup-pointer {
    left: 0;
    top: -18px;
    background-image: linear-gradient(45deg, var(--esColor-grey2) 10px, var(--esColor-theme2) 10px, var(--esColor-theme2) 12px, transparent 12px);
}

/* Popup above the activator */
.above.esPopup {
    border-width: 0 0 2px;
}

.above > .esPopupPointer {
    left: 0;
    bottom: -18px;
    background-image: linear-gradient(-45deg, transparent 12px, var(--esColor-theme2) 12px, var(--esColor-theme2) 14px, var(--esColor-grey2) 14px);
}

/* Popup right the activator */
.right.esPopup {
    border-width: 0 0 0 2px;
}

.right > .esPopupPointer {
    top: 0;
    left: -18px;
    background-image: linear-gradient(45deg, transparent 12px, var(--esColor-theme2) 12px, var(--esColor-theme2) 14px, var(--esColor-grey2) 14px);
}

/* Popup left the activator */
.left.esPopup {
    border-width: 0 2px 0 0;
}

.left > .esPopupPointer {
    top: 0;
    right: -18px;
    background-image: linear-gradient(-45deg, transparent 12px, var(--esColor-theme2) 12px, var(--esColor-theme2) 14px, var(--esColor-grey2) 14px);
}

.esProgressBar {
    background: var(--esColor-grey3);
    border-radius: 4px;
    border: 0 none;
    height: 8px;
    width: 100%;
}

/* Mozilla Firefox */
.esProgressBar::-moz-progress-bar {
    background: var(--esColor-theme2);
    border-radius: 4px;
}

/* Internet explorer / Edge */
.esProgressBar::-ms-fill {
    background-color: var(--esColor-theme2);
}

/* Webkit */
.esProgressBar::-webkit-progress-bar {
    background: transparent;
}

.esProgressBar::-webkit-progress-value {
    background: var(--esColor-theme2);
    border-radius: 4px;
}

.esProgressIndicator {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    position: relative;
}

.esProgressIndicator:before {
    bottom: 0;
    color: var(--esColor-theme1);
    content: attr(aria-valuenow) "%";
    height: 1em;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}

/* Hide progress percentage when none available */
.esProgressIndicator:not([aria-valuenow]):before {
    display: none;
}

.esProgressIndicator > div {
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
    display: block;
    height: 48px;
    width: 48px;
    border-color: var(--esColor-grey2) var(--esColor-grey2) var(--esColor-theme2) var(--esColor-theme2);
    /* Max animation time 10 minutus */
    animation: rotate 5s linear 120;
}

/* Stop animation and change color when 100% progress */
.esProgressIndicator[aria-valuenow="100"] > div {
    animation: none;
    border-color: var(--esColor-theme1);
}

/* Small, don't show percentage and reduce size */
.esProgressIndicator.small:before {
    content: none;
}

.esProgressIndicator.small > div {
    border-width: 2px;
    height: 16px;
    width: 16px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.esListbox {
    display: block;
    position: relative;
}

.esListbox > label {
    color: var(--esColor-theme1);
    display: block;
    font-size: var(--es-font-size-16);
    left: 0;
    padding: 0;
    position: relative;
    top: 0;
    white-space: nowrap;
    z-index: 2;
}

.esListbox > .esListboxButton,
.esListbox > select {
    -webkit-appearance: none;
    background-color: transparent;
    border-color: var(--esColor-grey4);
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 2px;
    display: block;
    color: inherit;
    font: inherit;
    height: calc(2em + 4px);
    padding: 0 1.1em 0 0;
    width: 100%;
    position: relative;
    text-align: left;
    z-index: 1;
}

.esListbox > .esListboxButton {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.esListbox > svg {
    bottom: 4px;
    box-sizing: border-box;
    color: var(--esColor-theme1);
    display: block;
    fill: currentColor;
    height: 2em;
    padding: 8px 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;
}

.esListboxButton[aria-expanded="true"] {
    background-color: var(--esColor-grey2);
    border-bottom: 2px solid var(--esColor-theme2);
    box-shadow: 0 0 0 16px var(--esColor-grey2), -16px -32px 0 0 var(--esColor-grey2), 16px -32px 0 0 var(--esColor-grey2);
}

.esListboxButton[aria-expanded="true"] + svg {
    color: var(--esColor-theme2);
    transform: scaleY(-1);
}

.esListboxPopup {
    align-content: stretch;
    border-color: var(--esColor-grey2);
    border-style: solid;
    border-width: 0 16px 16px;
    display: flex;
    margin: 0;
    min-height: 240px;
    padding-top: 44px;
    position: absolute;
    z-index: 2;
}

.esListboxList {
    background-color: #fff;
    flex: 1 1 100%;
    list-style: none;
    margin: 0;
    overflow: auto;
    overscroll-behavior: contain;
    padding: 16px 0;
}

.esListboxList > li {
    padding: 4px 16px;
}

.esListboxList > li:hover {
    background-color: var(--esColor-grey1);
}

.esListboxList > [aria-selected="true"] > span {
    border-bottom: 2px solid var(--esColor-theme2);
}

/* Tertiary styling */
.esListbox.tertiary > .esListboxButton,
.esListbox.tertiary > select {
    padding: 0 2.2em 0 0;
}

.esListbox.tertiary > svg {
    border-color: var(--esColor-grey4);
    border-radius: 0;
    border-style: solid;
    border-width: 2px;
    bottom: 4px;
    display: block;
    fill: currentColor;
    height: 2em;
    padding: 6px;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 2em;
    z-index: 1;
}

.tertiary > .esListboxButton[aria-expanded="true"] + svg {
    border-color: var(--esColor-theme2);
    background-color: var(--esColor-theme2);
    color: #fff;
}

.esSkipLink {
    background-color: var(--esColor-background);
    border: 0;
    clip: rect(0, 0, 0, 0);
    color: var(--esColor-foreground);
    height: 1px;
    left: 6px;
    margin: -1px;
    overflow: hidden;
    padding: 4px;
    position: fixed;
    text-decoration: none;
    top: 5px;
    width: 1px;
    z-index: 1000;
}

.esSkipLink:focus {
    clip: auto;
    height: auto;
    width: auto;
}

/* Container */
.esSlider {
    border: 0;
    font-size: 16px;
    line-height: var(--es-line-height);
    font-variant-numeric: lining-nums tabular-nums;
    margin: 0;
    padding: 0 0 2em;
    position: relative;
    width: 100%;
}

/** Input field **/
.esSliderInput {
    display: none;
}

/** Label field **/
.esSliderLabel {
    font-size: 20px;
    line-height: var(--es-line-height);
}

/** Slider scale labels **/
.esSliderScale {
    display: flex;
    justify-content: space-between;
}

.esSliderScaleLabel {
    margin-top: 12px;
    position: relative;
}

.esSliderScaleLabel:after {
    background-color: var(--esColor-grey4);
    content: '';
    display: block;
    height: 10px;
    left: 2px;
    position: absolute;
    top: -7px;
    width: 2px;
}

.esSliderScaleLabel:last-child:after {
    left: auto;
    right: 2px;
}

/** Slider track **/
.esSliderSelected {
    border-radius: 4px;
    background-color: var(--esColor-theme2);
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.esSliderTrack {
    background-color: var(--esColor-grey3);
    border-radius: 4px;
    height: 8px;
    position: relative;
    margin: 16px 0 0;
}

.esSlider.label .esSlider-track {
    margin: 40px 0 0;
}

/** Thumb button **/
.esSliderThumb,
.esSliderThumb:after,
.esSliderThumb:before {
    display: block;
    font: inherit;
    position: absolute;
}

.esSliderThumb {
    background-color: var(--esColor-theme2);
    border-radius: 50%;
    border: 3px solid var(--esColor-background);
    height: 18px;
    left: 0;
    padding: 0;
    transform: translate(-9px, -5px);
    width: 18px;
}

.esSlider.label .esSliderThumb:before {
    content: attr(aria-valuenow);
    left: 6px;
    position: absolute;
    top: -36px;
    transform: translateX(-50%);
}

.esSlider.label .esSliderThumb.move:before {
    left: 12px;
    transform: translateX(-100%);
}

.esSlider.label .esSliderThumb + .esSliderThumb.move:before {
    left: 0;
    transform: translateX(0%);
}

.esSlider.label .esSliderThumb:after {
    background-color: var(--esColor-grey4);
    content: '';
    height: 10px;
    left: 5px;
    top: -15px;
    width: 2px;
}

.esSliderThumb:active,
.esSliderThumb:hover {
    border-color: var(--esColor-theme2);
    background-color: var(--esColor-background);
}

.esSlider.label .esSliderThumb:active:after,
.esSlider.label .esSliderThumb:hover:after {
    height: 7px;
}

/** Thumb label **/
.esSliderThumbTooltip {
    background-position: -9px -10px, right bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%, 11px 100%;
    display: none;
    height: 36px;
    padding: 0 16px;
    position: absolute;
    right: 6px;
    bottom: calc(100% + 4px);
    background-image: linear-gradient(0deg, var(--esColor-theme2) 2px, var(--esColor-grey3) 0), linear-gradient(45deg, transparent 8px, var(--esColor-theme2) 0, var(--esColor-theme2) 10px, var(--esColor-grey3) 0);
}

.esSliderThumbTooltip.showRight {
    background-position: 9px -10px, left bottom;
    left: 6px;
    right: auto;
    background-image: linear-gradient(0deg, var(--esColor-theme2) 2px, var(--esColor-grey3) 0), linear-gradient(-45deg, transparent 8px, var(--esColor-theme2) 0, var(--esColor-theme2) 10px, var(--esColor-grey3) 0);
}

/* Show tooltip on focus, hover, etc */
.esSliderThumb:focus .esSliderThumbTooltip,
.esSliderThumb:hover .esSliderThumbTooltip,
.esSlider:active .esSliderThumbTooltip,
.esSlider:focus-within .esSliderThumbTooltip,
.esSlider:hover .esSliderThumbTooltip {
    display: block;
}

/* Checkbox sizes, by default size is inherited from the parent */
.esSwitch.small,
.esSwitchInput.small {
    font-size: var(--es-font-size-16);
    line-height: var(--es-line-height);
}

.esSwitch.medium,
.esSwitchInput.medium {
    font-size: var(--es-font-size-20);
    line-height: var(--es-line-height);
}

.esSwitch.large,
.esSwitchInput.large {
    font-size: var(--es-font-size-24);
    line-height: var(--es-line-height);
}

.esSwitchInput {
    -webkit-appearance: none;
    background-color: var(--esColor-grey2);
    border: 2px solid var(--esColor-background);
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    height: calc(1em + 4px);
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: calc(2em + 8px);
    transition: border-color var(--es-animation-hover), box-shadow var(--es-animation-hover);
    border-radius: calc(0.5em + 2px);
}

.esSwitchInput:checked {
    background-color: var(--esColor-theme1);
}

.esSwitchInput:hover {
    border-color: var(--esColor-background);
}

.esSwitchInput:focus {
    outline: none;
}

.esSwitchInput:before {
    background-color: var(--esColor-grey4);
    border-radius: 50%;
    color: var(--esColor-theme1);
    content: '';
    font-variant-numeric: oldstyle-nums proportional-nums;
    height: 1em;
    line-height: 0.9;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transform: translateX(-1.1em) scale(0.7);
    width: 1em;
    transition: color var(--es-animation-hover), transform var(--es-animation-hover);
}

.esSwitchInput:checked:before {
    background-color: var(--esColor-background);
    color: var(--esColor-background);
    content: '';
    transform: translateX(0%) scale(0.7);
}

/*.esSwitchInput {
    border-radius: calc(0.5em + 2px);
}*/

label > .esSwitchInput {
    margin-right: 0.4em;
}

.esSwitchInput + label {
    margin-left: 0.4em;
    vertical-align: middle;
}

.esSwitch > span {
    vertical-align: middle;
}

/* Disabled switch */
.esSwitchInput[disabled] {
    border-color: var(--esColor-disabled) !important;
    box-shadow: none !important;
}

.esSwitchInput[disabled]:before {
    background-color: var(--esColor-disabled);
}

.esSwitchInput[disabled] + label,
.esSwitchInput[disabled] + span {
    color: var(--esColor-grey6) !important;
}

/* Generic tab */
.esTabPanel > [role="tablist"] > [role="tab"] {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0 solid transparent;
    box-sizing: border-box;
    color: inherit;
    display: inline-block;
    font: inherit;
    height: 2em;
    margin: 0;
    padding: 2px 16px 0;
    transition: box-shadow var(--es-animation-hover);
    z-index: 0;
}

.esTabPanel > [role="tabpanel"][hidden] {
    display: none;
}

/* Horizontal tabs (default) */
.esTabPanel > [role="tabpanel"] {
    overflow: auto;
}

.esTabPanel > [role="tablist"] {
    box-shadow: 0 -1px 0 0 var(--esColor-foreground) inset;
}

/* Focus/Hover/Selected tab state */
.esTabPanel > [role="tablist"] > [role="tab"]:hover {
    box-shadow: 0 -4px 0 0 var(--esColor-theme2) inset;
}

.esTabPanel > [role="tablist"] > [aria-selected="true"][role="tab"] {
    box-shadow: 0 -4px 0 0 var(--esColor-foreground) inset;
}

.esTag {
    background-color: var(--esColor-grey2);
    border-radius: 1.6em;
    border: 2px solid transparent;
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    height: 2em;
    line-height: calc(2em - 4px);
    max-width: 100%;
    overflow: hidden;
    padding: 0 calc(0.8em - 2px);
    text-overflow: inherit;
    transition: background-color .3s, border-color .3s;
    vertical-align: middle;
    white-space: nowrap;
    margin: 0 8px 8px 0;
}

.esTagList{
    display: inline-flex;
    list-style-type: none;
    padding-left: 28px;
    padding-bottom: 8px;
    margin: -4px;
    text-overflow: ellipsis;
}

.esTagList > li{
    float: left;
}

div > .esTagList{
    display: flex;
}

/* Tag with icon */
.esTag svg {
    box-sizing: border-box;
    fill: currentColor;
    height: calc(2em - 4px);
    margin: 0 0.5em 0 calc(-0.3em + 2px);
    padding: calc(0.5em - 4px) 0;
    vertical-align: top;
    width: auto;
    transition: fill var(--es-animation-hover);
}

.esTag svg:last-child {
    margin: 0 calc(-0.3em + 2px) 0 0.5em;
}

a.esTag svg,
button.esTag svg {
    color: var(--esColor-theme1);
}

a.esTag:hover,
button.esTag:hover {
    background-color: var(--esColor-grey0);
    border-color: var(--esColor-theme2);
}

a.esTag:hover svg,
button.esTag:hover svg {
    color: currentColor;
}

/* Tag sizes */
.esTag.small {
    font-size: 14px;
}

.esTag.medium {
    font-size: 16px;
}

.esTag.large {
    font-size: 20px;
}

/* Tag messaging colors */
.esTag.confirm {
    background-color: var(--esColor-confirmation-ondark);
    color: black;
}

.esTag.info {
    background-color: var(--esColor-information-ondark);
    color: black;
}

.esTag.warning {
    background-color: var(--esColor-warning-ondark);
    color: black;
}

.esTable {
    border-collapse: collapse;
    font-variant-numeric: lining-nums tabular-nums;
    margin-bottom: 1em;
    max-width: 100%;
}

.esTable.fullWidth {
    width: 100%;
}

.esTable td,
.esTable th {
    font-weight: inherit;
    padding: 2px 4px;
    text-align: left;
}

.esTable > caption {
    background: inherit;
    padding: 1em;
}

/* Only show hover on table body rows */
.esTable > tbody > tr:hover > td,
.esTable > tbody > tr:hover > th,
.esTable > tr:hover > td,
.esTable > tr:hover > th {
    background-color: var(--esColor-grey1);
}

/* Remove padding for the first and last cell */
.esTable td:first-child,
.esTable th:first-child {
    padding-left: 0;
}

.esTable td:last-child,
.esTable th:last-child {
    padding-right: 0;
}

/* All table cell gets a light bottom border */
.esTable td {
    border-bottom: 1px solid var(--esColor-grey4);
}

/* The last row in the thead gets dark bottom border */
.esTable > thead > tr:last-child > th {
    border-bottom: 1px solid var(--esColor-foreground);
}

.esTooltip {
    background-color: var(--esColor-grey2);
    border-color: var(--esColor-theme2);
    border-style: solid;
    border-width: 0 0 2px;
    font-size: var(--es-font-size-16);
    left: 0;
    line-height: var(--es-line-height);
    opacity: 0;
    padding: 4px 8px;
    position: absolute;
    transition: opacity var(--es-animation-hover);
    top: 0;
    z-index: 99;
    /* 100% viewport width - 20px for the scrollbar */
    max-width: calc(100vw - 48px);
}

.esTooltip:after {
    /* Extra element so pointer can travel to tooltip */
    content: "";
    height: 18px;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
}

.esTooltip.small {
    width: 320px;
}

.esTooltip.medium {
    width: 480px;
}

.esTooltip.large {
    width: 640px;
}

.esTooltip.visible {
    opacity: 1;
}

.esTooltip[hidden] {
    display: none;
}

.esTooltip > div {
    bottom: -18px;
    display: block;
    height: 18px;
    left: 0;
    position: absolute;
    width: 18px;
    background-image: linear-gradient(-45deg, transparent 12px, var(--esColor-theme2) 12px, var(--esColor-theme2) 14px, var(--esColor-grey2) 14px);
}

/* Popup below the activator */
.esTooltip.below {
    border-width: 2px 0 0;
}

.esTooltip.below:after {
    bottom: 100%;
    top: auto;
}

.esTooltip.below > div {
    bottom: auto;
    top: -18px;
    background-image: linear-gradient(45deg, var(--esColor-grey2) 10px, var(--esColor-theme2) 10px, var(--esColor-theme2) 12px, transparent 12px);
}

/* Flip pointer horizontal */
.esTooltip.flip > div {
    left: auto;
    right: 0;
}

.esFooter {
    align-items: stretch;
    border-color: var(--esColor-theme2);
    border-style: solid;
    border-width: 2px 0 0;
    box-sizing: border-box;
    color: rgba(var(--esColor-base-dark-grey-rgb), 1);
    display: flex;
    flex-direction: column;
    font-size: var(--es-font-size-14);
    line-height: var(--es-line-height);
    margin: 32px 0 0;
    padding: 32px 8px;
    position: relative;
    width: 100%;
    z-index: 0;
}

.esFooterContent {
    flex: 1 1 auto;
    margin: 0 8px;
}

.esFooterLinks {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.esFooterLinks > li {
    display: block;
    margin: 0 0 16px 0;
    white-space: nowrap;
}

.esFooterNonsolus {
    align-items: flex-start;
    display: flex;
    margin: 0 8px 24px;
}

.esFooter-nonsolus * {
    display: block;
}

.esFooterRelx {
    align-items: flex-start;
    display: flex;
    margin: 24px 8px 0;
}

.esFooterRelx * {
    display: block;
}

@media (min-width: 576px) {
    .esFooter {
        padding-left: 16px;
        padding-right: 16px;
    }
    .esFooterLinks {
        flex-direction: row;
    }
    .esFooterLinks > li {
        margin: 0 24px 8px 0;
        white-space: nowrap;
    }
}

@media (min-width: 768px) {
    .esFooter {
        flex-direction: row;
    }
    .esFooterNonsolus {
        margin: 0 16px 0 8px;
    }
    .esFooterRelx {
        margin: 0 8px 0 16px;
    }
}

@media (min-width: 992px) {
    .esFooter {
        padding-left: 24px;
        padding-right: 24px;
    }
    .esFooterLinks > li {
        margin-right: 32px;
    }
    .esFooterNonsolus {
        margin-right: 24px;
    }
    .esFooterRelx {
        margin: 0 8px 0 24px;
    }
}

.esHeader {
    background-color: white;
    box-sizing: border-box;
    color: rgba(var(--esColor-base-dark-grey-rgb), 1);
    display: flex;
    flex-wrap: nowrap;
    font-size: 20px;
    height: 9em;
    justify-content: flex-end;
    line-height: calc(1em + 8px);
    min-height: 48px;
    padding: 8px 8px;
    position: relative;
}

.esHeader .round,
.esHeader .square {
    align-items: center;
    background-color: rgba(var(--esColor-base-dark-grey-rgb), 1);
    color: white;
    display: flex;
    flex: 0 0 auto;
    height: calc(1em + 20px);
    justify-content: center;
    line-height: 1;
    margin: 0;
    width: calc(1em + 20px);
}

.esHeader .round {
    border-radius: 50%;
}

.esHeaderIcons {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.esHeaderIcons:last-child {
    padding: 0;
}

.esHeaderIconsItem {
    display: none;
    margin: 0 8px 0 24px;
}

.esHeaderIconsExDec {
    font-size: 24px;
    color: black;
    text-align: right;
}

.esHeaderIconsExDec > a {
    font-size: 20px;
    font-weight: bold;
}

.esHeaderIconsItem.button {
    margin: 0 8px 0 8px;
}

.esHeaderIconsItem.svg + .esHeaderIconsItem.button {
    margin: 0 8px 0 24px;
}

.esHeaderIconsMobile {
    display: block;
}

.esHeaderIconsButton {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    background-color: transparent;
    border: 0;
    color: inherit;
    display: flex;
    font: inherit;
    line-height: inherit;
    margin: 0 -8px;
    padding: 6px 8px;
    transition: background-color var(--es-animation-hover);
    width: auto;
}

.esHeaderIconsButton svg {
    border-color: transparent;
    border-style: solid;
    border-width: 2px 0;
    box-sizing: content-box;
    display: block;
    fill: currentColor;
    height: calc(1em + 4px);
    transition: border-color var(--es-animation-hover);
    width: auto;
}

.esHeaderIconsButton:hover > svg {
    border-bottom-color: var(--esColor-theme2);
}

.background .esHeaderIconsButton {
    align-items: center;
    background-color: var(--esColor-grey7);
    color: white;
    height: calc(1em + 20px);
    justify-content: center;
    line-height: 1;
    width: calc(1em + 20px);
}

.background .esHeaderIconsButton[aria-expanded="true"],
.background .esHeaderIconsButton:hover {
    background-color: var(--esColor-theme2);
}

.esHeaderIconsButton.profile {
    font-size: 16px;
    line-height: var(--es-line-height);
}

.esHeaderIconsButton.profile span {
    border-color: transparent;
    border-style: solid;
    border-width: 2px 0;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: border-color var(--es-animation-hover);
    white-space: nowrap;
}

.esHeaderIconsButton.profile .round {
    transition: background-color var(--es-animation-hover);
    margin-left: 8px;
}

.esHeaderIconsButton.profile:hover span {
    border-bottom-color: var(--esColor-theme2);
}

.esHeaderIconsButton.profile:hover .round {
    background-color: var(--esColor-theme2);
}

.esHeaderLinks {
    align-items: center;
    display: none;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 7px 0 5px 0;
    white-space: nowrap;
}

.esHeaderIcons + .esHeaderLinks {
    padding: 3px 0 9px 0;
}

.esHeaderLinks > li {
    margin: 0 8px 0 24px;
}

.esHeaderProduct {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
}

.esHeaderProduct > a {
    align-items: center;
    color: var(--esColor-foreground);
    display: flex;
    text-decoration: none;
}

.esHeaderProductLogo {
    background: #bbbbbb;
    display: contents;
}

.noselect{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.esHeaderProductName {
    border-color: transparent;
    border-style: solid;
    border-width: 6px 0 2px;
    font-size: 18px;
    font-weight: 700;
    line-height: calc(1em + 8px);
    transition: border-color var(--es-animation-hover);
}

.esHeaderProductSvg {
    max-height: 32px;
    max-width: 100%;
}

@media (max-width: 576px) {
    .esHeaderProductSvg {
        width: auto;
    }
}

a:hover > .esHeaderProductName {
    border-bottom-color: var(--esColor-theme2);
}

.esHeaderProductBatch {
    display: none !important;
    font-size: 18px;
    margin-left: 1em;
    line-height: var(--es-line-height);
    padding: 8px 0 0;
}

.esHeaderSide {
    background-color: var(--esColor-grey0);
    bottom: 0;
    display: block;
    max-width: calc(100% - 16px);
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(0%) !important;
    visibility: visible;
    width: 320px;
    z-index: 9;
    transition: transform var(--es-animation-hover), visibility 0s;
}

.esHeaderSide[hidden] {
    transform: translateX(100%) !important;
    visibility: hidden;
    transition: transform var(--es-animation-hover), visibility var(--es-animation-hover) 0s;
}

.esHeaderSide:before {
    background-color: rgba(var(--esColor-grey2-rgb), 0.85);
    bottom: 0;
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 100vw;
}

.esHeaderSide[hidden]:before {
    content: none;
}

.esHeaderSideContent {
    bottom: 0;
    left: 0;
    overflow: hidden auto;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 0;
}

.esHeaderSideOverlay.visible {
    display: block;
}

.esHeaderSideClose {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
}

@media (min-width: 576px) {
    .esHeader {
        flex-wrap: wrap;
        min-height: 80px;
        padding: 10px 16px;
    }
    .esHeaderProduct {
        min-height: 60px;
    }
    .esHeaderProductBatch {
        display: block !important;
    }
    .esHeaderProductLogo {
        height: 48px;
        margin-right: 16px;
    }
    .esHeaderProductName {
        font-size: 24px;
        font-weight: 700;
    }
    .esHeaderProductSvg {
        max-height: 48px;
    }
}

@media (min-width: 768px) {
    .esHeaderLinks {
        display: flex;
    }
    .esHeader-icons {
        margin: 0 0 0 32px;
    }
    .esHeaderIconsItem {
        display: block;
    }
    .esHeader-icons > li:last-child {
        display: none;
    }
    .esHeaderProduct {
        padding: 0 96px 0 8px;
    }
    .esHeader-side {
        display: none;
    }
}

@media (min-width: 992px) {
    .esHeader {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.header
.esHeaderBanner {
    align-items: center;
    display: flex;
    margin-left: 32px;
    height: 80px;
    font-family: NexusSansWebPro, serif;
}

.esHeaderBannerAction {
    font-size: 16px;
    line-height: var(--es-line-height);
}

.esHeaderBannerAction > a {
    margin-right: 16px;
}

.esHeaderBannerIcon {
    margin: 0 16px 0 0 !important;
}

.esHeaderBannerIcon svg {
    fill: currentColor;
}

.esHeaderBannerName {
    font-size: 16px;
    line-height: var(--es-line-height);
    margin: 0;
}

.esHeaderNav {
    margin: 0 0 32px;
}

.esHeaderNavTitle {
    color: var(--esColor-grey6);
    font: inherit;
    margin: 0 0 16px;
}

.esHeaderNav ul {
    margin: 0;
    padding: 0;
}

.esHeaderButtons {
    display: flex;
    margin-top: 24px;
}

.esHeaderButtons .esButton + .esButton {
    margin-left: 16px;
}

.esHeaderUser {
    margin: 0 0 32px;
}

/* Make some space for the close button */
.esHeaderUser .esHeaderBannerName {
    margin-right: 32px;
}

.esHeaderUserAction .esLink + .esLink {
    margin-right: 16px;
}

.es-tag {
    background-color: var(--esColor-grey2);
    border-radius: 1.6em;
    border: 2px solid transparent;
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    height: 2em;
    line-height: calc(2em - 4px);
    max-width: 100%;
    overflow: hidden;
    padding: 0 calc(0.8em - 2px);
    text-overflow: ellipsis;
    transition: background-color .3s, border-color .3s;
    vertical-align: middle;
    white-space: nowrap;
}

/* Tag with icon */
.es-tag svg {
    box-sizing: border-box;
    fill: currentColor;
    height: calc(2em - 4px);
    margin: 0 0.5em 0 calc(-0.3em + 2px);
    padding: calc(0.5em - 4px) 0;
    vertical-align: top;
    width: auto;
    transition: fill var(--es-animation-hover);
}
.es-tag svg:last-child {
    margin: 0 calc(-0.3em + 2px) 0 0.5em;
}
a.es-tag svg,
button.es-tag svg {
    color: var(--esColor-theme1);
}
a.es-tag:hover,
button.es-tag:hover {
    background-color: var(--esColor-grey0);
    border-color: var(--esColor-theme2);
}
a.es-tag:hover svg,
button.es-tag:hover svg {
    color: currentColor;
}

/* Tag sizes */
.es-tag.small {
    font-size: 14px;
}
.es-tag.medium {
    font-size: 16px;
}
.es-tag.large {
    font-size: 20px;
}

/* Tag messaging colors */
.es-tag.confirm {
    background-color: var(--esColor-confirmation-ondark);
    color: black;
}
.es-tag.info {
    background-color: var(--esColor-information-ondark);
    color: black;
}
.es-tag.warning {
    background-color: var(--esColor-warning-ondark);
    color: black;
}

.focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
}

.hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(237, 242, 247, 0.5);
}

.focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(237, 242, 247, 0.5);
}

.loadingLogo {
    width: 173px;
    height: 192px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.errorScreenDialogButtons{
    padding-left: 30px;
    padding-top: 20px;
    font-family: NexusSansWebPro, serif;
}